export default function validateForms(values) {
    let errors ={}
    if(!values.name){
        errors.name = 'Name required'
    }
    if(!values.email){
        errors.email = 'Email required'
    }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)){
        errors.email = 'invalid email address'
    }

    if(!values.password){
        errors.password = 'Password required!'
    }else if(values.password.length < 6){
        errors.password = 'password must be atleast 6 character'
    }
    return errors
}
