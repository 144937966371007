import { navigate } from 'gatsby'

export const socialAuth = async (
  firebase,
  authProvider,
  setAuthError,
  setSocialAuthO,
  Type
) => {
  if (authProvider === 'Google') {
    try {
      await firebase.doSignInWithGoogle().then(socialAuthUser => {
        // Create a user in your Firebase Realtime Database too
        let neW = socialAuthUser.additionalUserInfo.isNewUser

        if (neW) {
          if (Type === 'SignUp') {
            setSocialAuthO(true)
          }

          firebase.db
            .collection('users')
            .doc(socialAuthUser.additionalUserInfo.profile.email)
            .set({
              username: socialAuthUser.additionalUserInfo.profile.name,
              id: socialAuthUser.user.uid,
              email: socialAuthUser.additionalUserInfo.profile.email,
              created: new Date().toLocaleString('en-GB', {
                timeZone: 'UTC',
                hour12: false,
                month: 'short',
                year: 'numeric',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              }), // email: socialAuthUser.additionalUserInfo.profile.email,
            })
          navigate('/newUser')
        } else {
          navigate('/')
        }
      })
    } catch (err) {
      if (Type === 'SignUp') {
        setSocialAuthO(false)
      }

      if (err.code === 'auth/popup-closed-by-user') {
        setAuthError(`${authProvider} Authentication popup closed, Try again!`)
      } else {
        setAuthError(err.message)
      }
    }
  }
  if (authProvider === 'Facebook') {
    try {
      await firebase.doSignInWithFacebook().then(socialAuthUser => {
        let neW = socialAuthUser.additionalUserInfo.isNewUser

        if (neW) {
          if (Type === 'SignUp') {
            setSocialAuthO(true)
          }
          firebase.db
            .collection('users')
            .doc(socialAuthUser.additionalUserInfo.profile.email)
            .set({
              username: socialAuthUser.additionalUserInfo.profile.name,
              email: socialAuthUser.additionalUserInfo.profile.email,
              id: socialAuthUser.user.uid,
              created: new Date().toLocaleString('en-GB', {
                timeZone: 'UTC',
                hour12: false,
                month: 'short',
                year: 'numeric',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              }),
            })
          navigate('/newUser')
        } else {
          navigate('/')
        }
      })
    } catch (err) {
      await firebase.login(err.email, 'surya007')
      await firebase.currentUser.linkWithCredential(err.credential)
      navigate('/')

      if (Type === 'SignUp') {
        setSocialAuthO(false)
      }
      if (err.code === 'auth/popup-closed-by-user') {
        setAuthError(`${authProvider} Authentication popup closed, Try again!`)
      } else {
        setAuthError(err.message)
      }
    }
  }
}
