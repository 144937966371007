import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import { IconContext } from 'react-icons'
import {
  FaFacebook,
  FaLinkedinIn,
  FaGoogle,
  FaEnvelope,
  FaLock,
  FaSmile,
  FaCode,
  FaCheckSquare,
  FaRocket,
} from 'react-icons/fa'
import CB from '../components/Checkbox'
import validateForms from '../components/validateForms'
import useFormValidation from '../components/useFormValidation'
import firebase from '../firebase'
import useAuth from '../firebase/useAuth'
import { socialAuth } from '../components/authFunctions'
import { Link, navigate } from 'gatsby'
import Button from 'components/CustomButtons/Button.jsx'

const INITIAL_STATE = {
  name: '',
  email: '',
  password: '',
}

const Type = 'SignUp'

const Sg = () => {
  const user = useAuth()
  const [role, setRole] = React.useState('')
  const [noFieldError, setNoFieldError] = React.useState(false)
  const [authError, setAuthError] = React.useState('')
  const [socialAuthO, setSocialAuthO] = React.useState(false)
  const terms = true
  const [firebaseError, setFirebaseError] = React.useState(null)

  React.useEffect(() => {
    //firebase
    if (user && !socialAuthO && !authError) {
      saveDB()
    }
  }, [user, noFieldError, authError, socialAuth])

  const saveDB = async () => {
    const { name, email } = values
    if (user) {
      let newUser = {
        name: name,
        email: email,
        telephone: '',
        dateRegistered: new Date().toLocaleDateString(),
        role: role,
        isRegistered: true,
      }

      if (role === 'Freelancer') {
        newUser = {
          name: name,
          email: email,
          telephone: '',
          linkedIn: '',
          gitHub: '',
          skillSets: '',
          Gdpr: 'true',
          share: '',
          fileName: '',
          cv: '',
          availability: {},
          dateRegistered: new Date().toLocaleDateString(),
          role: role,
          isRegistered: true,
        }
      }
      await firebase.db
        .collection('users')
        .doc(newUser.email)
        .set(newUser)

      if (role === 'Client') {
        await firebase.db
          .collection('client')
          .doc(newUser.email)
          .set(newUser)
        navigate('/editprofile')
      } else if (role === 'Freelancer') {
        await firebase.db
          .collection('freelancer')
          .doc(newUser.email)
          .set(newUser)
        navigate('/editprofile')
      }
    }
  }

  const authenticateUser = async () => {
    const { name, email, password } = values
    if (name && email && password && role) {
      try {
        setNoFieldError(false)
        await firebase.register(name, email, password)
        await firebase.login(email, password)
      } catch (err) {
        setFirebaseError(err.message)
      }
    } else {
      setNoFieldError(true)
    }
  }

  const { handleChanges, values, handleBlur, errors } = useFormValidation(
    INITIAL_STATE,
    validateForms
  )
  return (
    <Layout>
      <Wrapper>
        <Box>
          <TitleArea>
            <Heading>Register</Heading>
          </TitleArea>

          <ColWrap>
            <LeftCol>
              <CenterRow>
                <IconContext.Provider
                  value={{ color: '#ff0066', size: '80px' }}
                >
                  <FaRocket />
                </IconContext.Provider>
                <div style={{ flexDirection: 'column' }}>
                  <LeftHeader>Efficiency</LeftHeader>
                  <LeftBody>
                    Spend less time searching for new hires by using the
                    Flexatal platform
                  </LeftBody>
                </div>
              </CenterRow>
              <CenterRow>
                <IconContext.Provider
                  value={{ color: '#ff5a00', size: '80px' }}
                >
                  <FaCode />
                </IconContext.Provider>
                <div style={{ flexDirection: 'column' }}>
                  <LeftHeader>
                    High Quality Tech Professionals & Companies
                  </LeftHeader>
                  <LeftBody>
                    We do the hard work for you. Vetting candidates and
                    companies so you don't have to
                  </LeftBody>
                </div>
              </CenterRow>
              <CenterRow>
                <IconContext.Provider
                  value={{ color: '#ff9000', size: '40px' }}
                >
                  <FaCheckSquare />
                </IconContext.Provider>
                <div style={{ flexDirection: 'column' }}>
                  <LeftHeader>Match</LeftHeader>
                  <LeftBody>Find your perfect match today</LeftBody>
                </div>
              </CenterRow>
            </LeftCol>
            <RightCol>
              <div
                style={{
                  flexDirection: 'row',
                  paddingTop: 50,
                  paddingBottom: 50,
                }}
              >
                <SocialButton
                  backgroundColor="#ff0066"
                  onClick={() =>
                    socialAuth(
                      firebase,
                      'Facebook',
                      setAuthError,
                      setSocialAuthO,
                      Type
                    )
                  }
                >
                  <IconContext.Provider
                    value={{ color: 'white', size: '20px' }}
                  >
                    <FaFacebook />
                  </IconContext.Provider>
                </SocialButton>
                <SocialButton
                  backgroundColor="#ff5a00"
                  onClick={() =>
                    socialAuth(
                      firebase,
                      'Google',
                      setAuthError,
                      setSocialAuthO,
                      Type
                    )
                  }
                >
                  <IconContext.Provider
                    value={{ color: 'white', size: '20px' }}
                  >
                    <FaGoogle />
                  </IconContext.Provider>
                </SocialButton>
                <SocialButton
                  backgroundColor="#ff9000"
                  onClick={() => navigate('/popup')}
                >
                  <IconContext.Provider
                    value={{ color: 'white', size: '20px' }}
                  >
                    <FaLinkedinIn />
                  </IconContext.Provider>
                </SocialButton>
              </div>
              <CenterRow>
                <IconContext.Provider
                  value={{ color: '#606060', size: '20px' }}
                >
                  <FaSmile />
                </IconContext.Provider>
                <AuthInput
                  type="text"
                  name="name"
                  placeholder="Full Name..."
                  onChange={e => handleChanges(e)}
                  value={values.name}
                  onBlur={handleBlur}
                />
              </CenterRow>
              {errors.name && <ErrorText>{errors.name}</ErrorText>}
              <CenterRow>
                <IconContext.Provider
                  value={{ color: '#606060', size: '20px' }}
                >
                  <FaEnvelope />
                </IconContext.Provider>
                <AuthInput
                  type="email"
                  name="email"
                  placeholder="Email..."
                  onChange={e => handleChanges(e)}
                  value={values.email}
                  onBlur={handleBlur}
                />
              </CenterRow>
              {errors.email && <ErrorText>{errors.email}</ErrorText>}
              <CenterRow>
                <IconContext.Provider
                  value={{ color: '#606060', size: '20px' }}
                >
                  <FaLock />
                </IconContext.Provider>
                <AuthInput
                  type="password"
                  name="password"
                  placeholder="Password..."
                  onChange={e => handleChanges(e)}
                  value={values.password}
                />
              </CenterRow>
              {errors.password && <ErrorText>{errors.password}</ErrorText>}
              <div>Sign up as:</div>
              <CenterRow style={{ justifyContent: 'space-around' }}>
                <Item>
                  <RadioButton
                    type="radio"
                    value="Client"
                    name="clientButton"
                    checked={role === 'Client'}
                    onChange={() => setRole('Client')}
                  />
                  <RadioButtonLabel />
                  <div style={{ color: '#606060', fontSize: 18 }}>Client</div>
                </Item>
                <Item>
                  <RadioButton
                    type="radio"
                    value="Freelancer"
                    name="freelancerButton"
                    checked={role === 'Freelancer'}
                    onChange={() => setRole('Freelancer')}
                  />
                  <RadioButtonLabel />
                  <div style={{ color: '#606060', fontSize: 18 }}>
                    Freelancer
                  </div>
                </Item>
              </CenterRow>
              <TermsArea>
                <CB checked={terms} color="#ff0066" />
                <p style={{ color: '#606060', marginLeft: 15 }}>
                  To find out about how we process your data, please read our{' '}
                  <Link to="/privacy" style={{ color: '#ff0066' }}>
                    Privacy Policy
                  </Link>
                </p>
              </TermsArea>
              {firebaseError && <ErrorText>{firebaseError}</ErrorText>}
              {authError && <ErrorText>{authError}</ErrorText>}
              {noFieldError && <ErrorText>Fill all required fields</ErrorText>}
              <Button
                round
                style={{ marginTop: 30 }}
                disabled={!role ? true : false}
                color="rose"
                onClick={() => authenticateUser()}
              >
                Get started
              </Button>
            </RightCol>
          </ColWrap>
          <ButtonArea>
            <Button simple color="primary" size="lg">
              <Link style={{ color: '#ff0066' }} to="/login">
                Already a member? Login
              </Link>
            </Button>
          </ButtonArea>
        </Box>
      </Wrapper>
    </Layout>
  )
}

export default Sg

const Wrapper = styled.div`
  background-image: url('../../static/uploads/flexBackground.jpg');
  background-size: cover;
  background-position: top center;
  display: flex;
  min-height: 90vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Box = styled.div`
  background-color: white;
  padding: 5%;
  @media (max-width: 1200px) {
    width: 80vw;
  }
  @media (max-width: 1000px) {
    width: 90vw;
  }
`

const TitleArea = styled.div``

const Heading = styled.h1`
  font-weight: bold;
  padding: 10px 0;
  text-align: center;
`
const ColWrap = styled.div`
  display: flex;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`

const LeftCol = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    width: 100%;
  }
`

const LeftHeader = styled.h1`
  font-weight: bold;
  font-size: 24px;
  margin-left: 25px;
`

const LeftBody = styled.p`
  font-size: 18px;
  color: #606060;
  margin-left: 25px;
`

const RightCol = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    width: 100%;
  }
`

const ButtonArea = styled.div`
  text-align: center;
  padding-top: 20px;
  padding-bottom: 60px;
`

const SocialButton = styled.button`
  background-color: ${props => props.backgroundColor};
  border: none;
  margin: 0 5px;
  height: 40px;
  width: 40px;
  border-radius: 4px;
`

const AuthInput = styled.input`
  width: 100%;
  background-color: transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  border-bottom-width: 1px;
  border-bottom-color: ${props =>
    props.value.length > 0 ? '#f09000' : '#606060'};
  color: #606060;
  font-size: 18px;
  padding: 10px 0;
  margin-left: 10px;
  ::placeholder {
    font-size: 18px;
  }
`

const CenterRow = styled.div`
  display: flex;
  width: 80%;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
`

const ErrorText = styled.p`
  text-align: center;
  font-weight: bold;
  color: #f90000;
`

const TermsArea = styled.div`
  display: flex;
  width: 80%;
`
const Item = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  position: relative;
`

const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: transparent;
  border: 3px solid #ff0066;
`
const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;

  &:checked + ${RadioButtonLabel} {
    background: #ff0066;
    border: 1px solid #ff0066;
  }
`
