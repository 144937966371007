import React from "react";

function useFormValidation(initialState,validate) {

    const [values,setValues] = React.useState(initialState);
    const [errors,setErrors] = React.useState({});
    const [isSubmitting,setSubmitting] = React.useState(false);

    React.useEffect(() =>{
        
      if(isSubmitting){
          const noErrors = Object.keys(errors).length === 0
          if(noErrors){
              
              
              setSubmitting(true)
          }else{
              setSubmitting(false)
          }
      }
    },[errors])
    const handleChanges = (event) =>{
       event.persist()
        setValues(preValue =>( {...preValue, [event.target.name]: event.target.value}))
        const validationErrors = validate(values)
        setErrors(validationErrors)
    }
    const handleBlur = () =>{
        const validationErrors = validate(values)
        
        setErrors(validationErrors)
    }
    
    return {handleChanges,values,handleBlur,errors,isSubmitting}
}

export default useFormValidation;
